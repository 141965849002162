/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    text-align: center;
    margin-bottom: 0;

    .site-link {
        font-size: 26px;
        line-height: 56px;
        letter-spacing: -1px;

        &,
        &:visited {
            color: $grey-color-dark;
        }
    }
}

.site-description {
    text-align: center;
    padding-bottom: 10px;
}

.site-nav {
    text-align: center;
    padding-bottom: 10px;

    .menu-icon {
        display: none;
    }

    .page-link {
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-phone) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;
        padding-bottom: 0px;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            color: $text-color;
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    text-align: center;
    font-size: 15px;
    color: $grey-color;
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.home {
    .separator {
        padding-top: $spacing-unit;
    }

    .home-title {
        display: none;
    }
}



/**
 * Posts
 */
.post-header {
    margin-bottom: 0;
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-title {
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 1;

    .post-marker {
        font-size: 30px;
    }

    @include media-query($on-laptop) {
        font-size: 30px;

        .post-marker {
            font-size: 25px;
        }
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}



/**
 * Archives
 */
.archive-list {
    margin-left: 0;
    list-style: none;

    .post-link {
        margin-left: 20px;
    }
}
